<template>
  <b-card class="mb-3">
    <div style="font-size: 1.25em" v-if="item.obligationtype"><b-badge :variant="badgeVariant(item.obligationtype.name)">{{item.obligationtype.name}}</b-badge></div>
    <h5><b-link class="mr-3" :to="{ name: 'CommoditiesObligation', params: { id: item.id }}">{{item.name}}</b-link> <b-button class="mr-3" v-if="permission.addObligation" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'impactobligation' }}">Edit</b-button></h5>
    <div><strong>description</strong></div>
    <div class="mb-2"><new-lines :content="item.description"></new-lines></div>
    <div><strong>context / entity scope</strong></div>
    <div class="mb-2"><new-lines :content="item.context"></new-lines></div>
    <div v-if="item.dates.length > 0">
      <div v-for="date in item.dates" :key="'date' + date.id">
        <strong>{{date.date}}</strong> - {{date.name}}
      </div>
    </div>
    <div v-if="item.links.length > 0">
      <div><strong>External links</strong></div>
      <div v-for="link in item.links" :key="'link'+link.id">
        <div class="mb-2">
          <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import NewLines from '@/components/NewLines.vue'
export default {
  name: 'ObligationCommoditiesComponent',
  props: ['item'],
  components: {
    NewLines
  },
  computed: {
  },
  created: function () {
  },
  data () {
    const data = {
      badgeVariant: function (type) {
        let res = 'primary'
        if (type) {
          if (type.toLowerCase() === 'obligation') {
            res = 'danger'
          }
          if (type.toLowerCase() === 'entity') {
            res = 'success'
          }
          if (type.toLowerCase() === 'context') {
            res = 'info'
          }
        }
        return res
      },
      permission: {
        addObligation: false
      }
    }
    return data
  },
  methods: {
  }
}
</script>
