<template>
<div>
  <div v-if="!loading && permission.read">
  <b-container class="my-4 py-4 border-bottom">
    <b-row class="mb-4" v-if="savingStep">
      <b-col>
      </b-col>
      <b-col>
          <span>
            <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
          </span>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!savingStep">
      <b-col>
      </b-col>
      <b-col>
        <div>{{annotation.updated_by}} - {{moment(annotation.updated_at).format('YYYY-MM-DD HH:mm')}}</div>
        <div><strong>status:</strong> {{step.name}}</div>
        <div>
          <span>
            <b-button class="mr-4" v-if="step.downStep && permissionWorkflowDown" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
            <b-button v-if="step.upStep && permissionWorkflowUp" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="annotation.regulation">
      <b-col>
        <div><strong><b-link :to="{ name: 'RegDecompWorkspace', params: { id: annotation.regulation.id }}">{{annotation.regulation.name_short}} | {{annotation.regulation.id}}</b-link></strong></div>
        <div>{{annotation.regulation.name_long}}</div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span style="font-size: 1.25em" v-if="annotation.obligationtype"><b-badge :variant="badgeVariant">{{annotation.obligationtype.name}}</b-badge></span> citation: {{annotation.citation}} | id: {{annotation.id}}
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <div v-if="annotation.topic"><strong>{{annotation.topic}}</strong></div>
        <div>
          <annotation-history-modal :annotation="annotation"></annotation-history-modal>
          <strong style="font-size: 1.25em">{{annotation.name}}</strong>
          <b-button class="ml-4" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: annotation.id, model: 'obligation' }}">Edit</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.active.length > 0">
      <b-col>
        <div v-for="row in annotation.active" :key="'active'+row.id">
          This <strong>{{row.relationship.name}}</strong> {{row.obligation.name}} in <b-link :to="{ name: 'RegDecompWorkspace', params: { id: row.obligation.regulation.id }}" class="mr-2">{{row.obligation.regulation.name_short}}</b-link>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.passive.length > 0">
      <b-col>
        <div v-for="row in annotation.passive" :key="'passive'+row.id">
          This <strong>{{row.relationship.passive}}</strong> {{row.obligation.name}} in <b-link :to="{ name: 'RegDecompWorkspace', params: { id: row.obligation.regulation.id }}" class="mr-2">{{row.obligation.regulation.name_short}}</b-link>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.text">
      <b-col>
        <div><strong>Regulatory text</strong></div>
        <div><new-lines :content="annotation.text"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.description">
      <b-col>
        <div><strong>Description</strong></div>
        <div><new-lines :content="annotation.description"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.context">
      <b-col>
        <div><strong>Context</strong></div>
        <div><new-lines :content="annotation.context"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.standard">
      <b-col>
        <div><strong>Minimum standard</strong></div>
        <div><new-lines :content="annotation.standard"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.lagging">
      <b-col>
        <div><strong>Lagging market practice</strong></div>
        <div><new-lines :content="annotation.lagging"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.market_practice">
      <b-col>
        <div><strong>Leading market practice</strong></div>
        <div><new-lines :content="annotation.market_practice"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.date_effective">
      <b-col>
        <div><strong>Effective date</strong></div>
        <div>{{moment(annotation.date_effective).format('YYYY-MM-DD')}}</div>
      </b-col>
    </b-row>
    <b-row class="my-4">
      <b-col>
        <div class="mb-4" v-if="annotation.initiatives.length > 0">
          <div><strong>Initiatives</strong></div>
          <div v-for="item in annotation.initiatives" :key="'initiative-' + item.id">
            <b-link :to="{ name: 'CommoditiesInitiative', params: { id: item.id } }">{{ item.name }}</b-link>
          </div>
        </div>
        <div class="mb-4" v-if="annotation.entities.length > 0">
          <div><strong>Entities</strong></div>
          <div v-for="item in annotation.entities" :key="'entity-' + item.id">
            {{ item.name }}
          </div>
        </div>
        <div class="mb-4" v-if="annotation.requirements.length > 0">
          <div><strong>Requirements</strong></div>
          <div v-for="item in annotation.requirements" :key="'requirement-' + item.id">
            {{ item.name }}
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="mb-4" v-if="annotation.areas.length > 0">
          <div><strong>Functions</strong></div>
          <div v-for="item in annotation.areas" :key="'area-' + item.id">
            {{ item.name }}
          </div>
        </div>
        <div class="mb-4" v-if="annotation.riskcomponents.length > 0">
          <div><strong>Risk components</strong></div>
          <div v-for="item in annotation.riskcomponents" :key="'riskcomponents-' + item.id">
            {{ item.name }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="comments.length > 0">
      <b-col>
        <div v-for="comment in comments" :key="'comment'+comment.id">
          <div>
            <small>{{comment.username}}: at {{moment(comment.created_at).format('HH:mm')}} on {{moment(comment.created_at).format('YYYY-MM-DD')}}</small>
            <b-button v-if="editAlwaysOn" class="mx-2" variant="outline-primary" size="sm" v-on:click="commentDelete(comment.id)">delete</b-button>
          </div>
          <div class="mb-2">
            {{comment.content}}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="annotation.links.length > 0" class="my-4">
      <b-col>
        <div><strong>External links</strong></div>
        <div v-for="link in annotation.links" :key="'link'+link.id">
          <div class="mb-2">
            <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="annotation.controls.length > 0" class="my-4">
      <b-col>
        <div><strong>Controls</strong></div>
        <div v-for="control in annotation.controls" :key="'control'+control.id">
          <div class="mb-2">
            <b-link class="mr-4" :to="{ name: 'Control', params: { id: control.id }}">{{control.name}}</b-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
  </div>
  <div>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permission.read">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
  </b-container>
  </div>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import workflow from '@/workflows/annotation'
import AnnotationHistoryModal from '@/components/AnnotationHistoryModal.vue'
import NewLines from '@/components/NewLines.vue'

export default {
  name: 'ObligationCommodities',
  components: {
    AnnotationHistoryModal,
    NewLines
  },
  computed: {
    badgeVariant: function () {
      let res = 'primary'
      if (this.annotation.obligationtype) {
        if (this.annotation.obligationtype.name.toLowerCase() === 'obligation') {
          res = 'danger'
        }
        if (this.annotation.obligationtype.name.toLowerCase() === 'entity') {
          res = 'success'
        }
        if (this.annotation.obligationtype.name.toLowerCase() === 'context') {
          res = 'info'
        }
      }
      return res
    },
    obligations: {
      get () {
        return this.$store.state.commoditiesObligations
      },
      set (payload) {
        this.$store.commit('setCommoditiesObligations', payload)
      }
    },
    permissionWorkflowDown: function () {
      let result = false
      if (this.step.id > 7 && ac.can(this.user.acgroups).readAny('obligationWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id <= 7 && ac.can(this.user.acgroups).updateAny('obligation').granted) {
        result = true
      }
      return result
    },
    permissionWorkflowUp: function () {
      let result = false
      if (this.step.id >= 7 && ac.can(this.user.acgroups).readAny('obligationWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id < 7 && ac.can(this.user.acgroups).updateAny('obligation').granted) {
        result = true
      }
      return result
    },
    step: function () {
      const step = workflow.find(x => x.id === this.annotation.status_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permission.edit = ac.can(this.user.acgroups).updateAny('obligation').granted
    this.load()
  },
  data () {
    return {
      annotation: {},
      comments: [],
      editAlwaysOn: true,
      moment: moment,
      imported: false,
      loading: true,
      permission: {
        read: true,
        edit: false
      },
      regulation: {},
      savingStep: false,
      workflow: workflow,
      workflowStatusOptions: []
    }
  },
  methods: {
    load: async function () {
      try {
        this.$stat.log({ page: 'obligation', action: 'open obligation' })
        this.regulation = null
        this.annotation = await this.$Amplify.API.get('cosmos', `/obligation/${this.$route.params.id}`)
        if (this.obligations.length > 0) {
          const index = this.obligations.findIndex(x => x.id === this.annotation.id)
          if (index === -1) {
            this.obligations.push(this.annotation)
          } else {
            this.obligations[index] = this.annotation
          }
        }
        document.title = this.annotation.name
        if (this.annotation.comments) {
          this.comments = this.annotation.comments
        }
        this.loading = false
      } catch (e) {
        console.log(e)
        this.$logger.warn('getting reulation error', e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        // let response = await this.$http(config)
        const params = {
          body: {
            status_id: id
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/obligation/${this.annotation.id}`, params)
        const paramsLog = {
          body: {
            obligation_id: this.annotation.id,
            regulation_id: this.regulation.id,
            action: 'moved workflow step to ' + this.step.name,
            username: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', '/standard/obligationslog', paramsLog)
        this.annotation.status_id = id
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  }
}
</script>

<style scoped>
</style>
